// custom typefaces
import "typeface-nunito"
import "typeface-roboto"

// normalize CSS across browsers
import "./src/normalize.css"
// custom CSS styles
import "./src/globalStyles.css"

// Highlighting for code blocks
import "prismjs/themes/prism.css"

//Carousel for Testimonials
import "react-responsive-carousel/lib/styles/carousel.min.css";

//maintain scroll position for filters that have separate urls
export const shouldUpdateScroll = ({prevRouterProps, routerProps}) => {
  //ability to manually override by passing scroll to Link state
  if (typeof routerProps?.location?.state?.scroll !== 'undefined') {
    return routerProps.location.state.scroll;
  }

  const prevPathname = prevRouterProps?.location?.pathname ?? null;
  const pathname = routerProps?.location?.pathname ?? null;
  //list of paths to maintain scroll position when both new and previous route contain that pathname
  const noScrollPaths = [`/looker-studio-templates/`]
  // if the new route is part of the list above, scroll to top (0, 0)
  for (const noScrollPath of noScrollPaths) {
    if (pathname?.indexOf(noScrollPath) !== -1 && prevPathname?.indexOf(noScrollPath) !== -1) {
      return false;
    }
  }

  return true;
}
